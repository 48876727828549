<template>
  <svg class="page-transition-bubble" height="100%" preserveAspectRatio="none" viewBox="0 0 100 100" width="100%">
    <path ref="elPath" class="_path" d="M 0 100 V 100 Q 50 100 100 100 V 100 z" vector-effect="non-scaling-stroke" />
  </svg>
</template>

<script lang="ts" setup>
import type { TTransitionExpose } from '~/components/transitions/pageTransition/transition/transition.type.js';
import { ELenisEvents } from '~/lib/lenis/lenis.enum.js';
import './bubble.css';

defineExpose<TTransitionExpose>({
  beforeEnter,
  enter,
  afterEnter,
  beforeLeave,
  leave,
  afterLeave,
})

const { $gsap } = useNuxtApp()
const elPath = ref()

const paths = {
  step1: {
    unfilled: 'M 0 100 V 100 Q 50 100 100 100 V 100 z',
    inBetween: {
      curve1: 'M 0 100 V 50 Q 50 0 100 50 V 100 z',
      curve2: 'M 0 100 V 50 Q 50 100 100 50 V 100 z',
    },
    filled: 'M 0 100 V 0 Q 50 0 100 0 V 100 z',
  },
  step2: {
    filled: 'M 0 0 V 100 Q 50 100 100 100 V 0 z',
    inBetween: {
      curve1: 'M 0 0 V 50 Q 50 0 100 50 V 0 z',
      curve2: 'M 0 0 V 50 Q 50 100 100 50 V 0 z',
    },
    unfilled: 'M 0 0 V 0 Q 50 0 100 0 V 0 z',
  },
}

let isAnimating = false

function beforeEnter(el: HTMLElement) { }

function enter(el: HTMLElement, callback: () => void) {
  if (isAnimating) return
  isAnimating = true

  // Scroll to top
  document.dispatchEvent(
    new CustomEvent(ELenisEvents.SCROLL_TO, {
      detail: { target: 0, immediate: true },
    }),
  )

  // Anim
  $gsap
    .timeline({
      onComplete: () => {
        // console.log('Enter complete')
        isAnimating = false
        callback()
      },
    })
    .set(elPath.value, {
      attr: { d: paths.step2.filled },
    })
    .to(elPath.value, {
      duration: 0.2,
      ease: 'power1.in',
      attr: { d: paths.step2.inBetween.curve1 },
    })
    .to(elPath.value, {
      duration: 1,
      ease: 'power3.out',
      attr: { d: paths.step2.unfilled },
    })
}

function afterEnter(el: HTMLElement) {
  document.dispatchEvent(new CustomEvent(ELenisEvents.SCROLL_UNLOCK))
}

function beforeLeave(el: HTMLElement) {
  document.dispatchEvent(new CustomEvent(ELenisEvents.SCROLL_LOCK))
}

function leave(el: HTMLElement, callback: () => void) {
  if (isAnimating) return
  isAnimating = true

  // Anim
  $gsap.killTweensOf(elPath.value)
  $gsap
    .timeline({
      onComplete: () => {
        isAnimating = false
        console.log('Leave complete')
        callback()
      },
    })
    .set(elPath.value, {
      attr: { d: paths.step1.unfilled },
    })
    .to(
      elPath.value,
      {
        duration: 0.8,
        ease: 'power3.in',
        attr: { d: paths.step1.inBetween.curve1 },
      },
      0,
    )
    .to(elPath.value, {
      duration: 0.2,
      ease: 'power1.out',
      attr: { d: paths.step1.filled },
    })
}

function afterLeave(el: HTMLElement) { }
</script>
